<template>
  <b-container fluid>
    <bo-page-title />

    <section id="journal" v-if="isList">
      <b-tabs>
        <b-tab title="Content">
          <HeroSection v-if="moduleRole('hero')" :modulePage="modulePage" />

          <b-card v-if="isList" no-body>
            <b-card-header>
              <b-row>
                <b-col lg="2">
                  <h5 class="card-title">{{ pageTitle }} List </h5>
                </b-col>
                <b-col lg="3">
                  <b-form-group>
                    <v-select placeholder="Select Status" @input="doFill" v-model="filter.status" :options="Config.mr.statusJournalOptions" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group>
                    <v-select placeholder="Select Category" @input="doFill" v-model="filter.category" :options="mrCategory" label="label" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <SearchInput :value.sync="filter.search" @search="doFill" />
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row class="gutter-2" v-if="(data.data || []).length">
                <b-col lg="3" v-for="(value, index) in data.data" :key="index">
                  <article class="card_list">
                    <div class="wrap_thumb_list">
                      <img :src="uploader(value.mj_image)" />
                      <div class="bulle_cta">
                        <b-button v-if="moduleRole('edit')" variant="secondary" class="btn-icon" pill :to="{name: $route.name, params: { pageSlug: value.mj_id }}">
                          <i class="fas fa-pencil-alt" />
                        </b-button>
                        <b-button v-if="moduleRole('delete')" variant="danger" class="btn-icon" pill @click="doDelete(index, value)">
                          <i class="far fa-trash-alt" />
                        </b-button>
                      </div>
                    </div>
                    <div class="wrap_post_content">
                      <b-badge variant="success" v-if="value.mj_status == 'P'">Publish</b-badge>
                      <b-badge variant="warning" v-else-if="value.mj_status == 'D'">Draft</b-badge>
                      <b-badge variant="danger" v-else-if="value.mj_status == 'N'">Inactive</b-badge>
                      <b-badge variant="transparent" class="label-purple text-white ml-1" v-if="value.mj_is_featured == 'Y'">Featured</b-badge>
                      <p class="category">{{value.mj_category_id}}</p>
                      <h3>{{ value.mj_title_id }}</h3>

                      <div class="text-center mt-3">
                        <i class="ti-eye"></i> {{ value.mj_views || 0 }}
                      </div>
                    </div>
                  </article>
                </b-col>
              </b-row>
              <b-row v-else class="justify-content-center">
                <b-col lg="3">
                  <div class="text-center">
                    <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                    <h4 align="center"><span>No {{ pageTitle }} listed yet</span></h4>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
              <b-pagination
              class="mb-0"
              v-model="pageNo"
              :per-page="data.per_page"
              :total-rows="data.total"
              />
            </b-card-footer>
          </b-card>          
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
    </section>

    <template v-else>
      <Form :row.sync="row" :mrTags.sync="mrTags" :mrCategory.sync="mrCategory" v-bind="passToSub" />
    </template>

    
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
import SEOSection from "@/components/SEOSection"
import HeroSection from '@/components/HeroSection'
const _ = global._

export default {
  extends: GlobalVue,
  components: {
    Form,
    SEOSection,
    HeroSection,
  },
  data() {
    return {
      idKey:'mj_id',
      statusKey:'mj_is_active',
      dataModal: {},
      mrCategory: [],
      mrValidationCategory: {},
      mrUser: [],
      mrTags: [],
      linkDetailID: '',
      linkDetailEN: ''
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{}, {
        mrValidationCategory: this.mrValidationCategory,
        mrUser: this.mrUser,
        linkDetailID: this.linkDetailID,
        linkDetailEN: this.linkDetailEN,
      })
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    doFill(){
      this.doFilter()
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>