<template>
  <div>
    <validation-observer ref="VForm" v-slot="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-tabs>
          <b-tab title="Journal Content">
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <b-col lg="8">
                    <b-row>

                      <b-col lg="6">
                        <b-form-group label-for="articleCat">
                          <template #label>Category<span class="text-danger mr5">*</span></template>
                          <b-input-group>
                            <v-select id="articleCat" v-model="row.mj_category" :options="mrCategory" label="label" :reduce="v => v.value" />
                            <b-input-group-append>
                              <b-button type="button" @click="openModalAddNewCategory" variant="success" v-b-tooltip.hover.noninteractive="'Add New Category'"> <i class="fas fa-plus"></i> </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <VValidate name="Category" v-model="row.mj_category" :rules="mrValidation.mj_category" />
                        </b-form-group>
                      </b-col>
                      <div class="w-100"></div>
                      <b-col lg="8">
                        <b-form-group label-for="articleCat">
                          <template #label>Tags<span class="text-danger mr5">*</span></template>
                          <b-input-group>
                            <v-select multiple id="articleCat" v-model="row.mj_tags" :options="mrTags" label="label" :reduce="v => v.value" />
                            <b-input-group-append>
                              <b-button type="button" @click="openModalAddNewTags" variant="success" v-b-tooltip.hover.noninteractive="'Add New Tag'"> <i class="fas fa-plus"></i> </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <VValidate name="Tags" v-model="row.mj_tags" rules="required" />
                        </b-form-group>
                      </b-col>

                      <b-col lg="12">
                        <b-tabs v-model="tabLanguage">
                          <b-tab title="Content (ID)">
                            <b-card>
                              <b-form-group>
                                <template #label>Title (ID) <span class="text-danger">*</span></template>
                                <b-form-input v-model="row.mj_title_id" @input="watchTitle('id')" placeholder="e.g 5 alasan untuk membeli komputer desktop"></b-form-input>
                                <VValidate 
                                  name="Title (ID)" 
                                  v-model="row.mj_title_id" 
                                  :rules="{...mrValidation.mj_title_id}" 
                                />
                              </b-form-group>
                              <b-form-group>
                                <template #label>Slug (ID) <span class="text-danger">*</span></template>
                                <b-form-input :disabled="row.mj_have_published == 'Y'" :formatter="e => String(e).slugify()" v-model="row.mj_slug_id"></b-form-input>
                                <VValidate 
                                  name="Slug (ID)" 
                                  v-model="row.mj_slug_id" 
                                  :rules="{...mrValidation.mj_slug_id}" 
                                />
                              </b-form-group>
                              <b-form-group>
                                <template #label>Content (ID)<span class="text-danger mr5">*</span></template>
                                <QuillEditor 
                                  key="articleContentID"
                                  :value.sync="row.mj_content_id"
                                />
                                <VValidate 
                                  name="Content (ID)" 
                                  v-model="row.mj_content_id" 
                                  :rules="mrValidation.mj_content_id" 
                                />
                              </b-form-group>
                            </b-card>
                          </b-tab>
                          <b-tab title="Content (EN)">
                            <b-card>
                              <b-form-group>
                                <template #label>Title (EN) <span class="text-danger">*</span></template>
                                <b-form-input v-model="row.mj_title_en" @input="watchTitle('en')" placeholder="e.g 5 reasons to buy a desktop computer"></b-form-input>
                                <VValidate 
                                  name="Title (EN)" 
                                  v-model="row.mj_title_en" 
                                  :rules="{...mrValidation.mj_title_en}" 
                                />
                              </b-form-group>
                              <b-form-group>
                                <template #label>Slug (EN) <span class="text-danger">*</span></template>
                                <b-form-input :disabled="row.mj_have_published == 'Y'" :formatter="e => String(e).slugify()" v-model="row.mj_slug_en"></b-form-input>
                                <VValidate 
                                  name="Slug (EN)" 
                                  v-model="row.mj_slug_en" 
                                  :rules="{...mrValidation.mj_slug_en}" 
                                />
                              </b-form-group>
                              <b-form-group>
                                <template #label>Content (EN)<span class="text-danger mr5">*</span></template>
                                <QuillEditor 
                                  key="articleContentEN"
                                  :value.sync="row.mj_content_en"
                                />
                                <VValidate 
                                  name="Content (EN)" 
                                  v-model="row.mj_content_en" 
                                  :rules="mrValidation.mj_content_en" 
                                />
                              </b-form-group>
                            </b-card>
                          </b-tab>
                        </b-tabs>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col lg="4">
                    <b-form-group>
                      <template #label>Image <span class="text-danger">*</span></template>
                      <Uploader v-model="row.mj_image" type="journal" />
                      <VValidate name="Image" v-model="row.mj_image" :rules="mrValidation.mj_image" />
                    </b-form-group>
                    <b-form-group>
                      <label>Alt Image<span class="text-danger mr5">*</span></label>
                      <b-form-input id="input-formatter" v-model="row.mj_alt_image" placeholder="e.g Image title"></b-form-input>
                      <VValidate name="Alt Image" v-model="row.mj_alt_image" :rules="mrValidation.mj_alt_image" />
                    </b-form-group>
                    <b-form-group label-for="articlePubDate">
                      <template #label>Publisher<span class="text-danger mr5">*</span></template>
                      <b-form-input v-model="row.mj_publisher" placeholder="e.g John Doe"></b-form-input>
                      <VValidate name="Publisher" v-model="row.mj_publisher" :rules="mrValidation.mj_publisher" />
                    </b-form-group>
                    <b-form-group label-for="articlePubDate">
                      <template #label>Publish Date<span class="text-danger mr5">*</span></template>
                      <b-form-datepicker id="articlePubDate" v-model="row.mj_publish_date" />
                      <VValidate name="Publish Date" v-model="row.mj_publish_date" :rules="mrValidation.mj_publish_date" />
                    </b-form-group>
                    <b-form-group label-for="articleStatus">
                      <template #label>Status <span class="text-danger mr5">*</span></template>
                      <b-form-radio-group
                        id="articleStatus"
                        v-model="row.mj_status"
                        :options="globalComp.Config.mr.statusJournalOptions"/>
                      <VValidate name="Status" v-model="row.mj_status" :rules="mrValidation.mj_status" />
                    </b-form-group>
                    <b-form-group label-for="articleStatus">
                      <template #label>
                        Featured 
                        <b-badge variant="info" pill class="help-badge" v-b-tooltip.hover.right="'Show in home page'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-checkbox
                        id="featured"
                        v-model="row.mj_is_featured"
                        name="featured"
                        value="Y"
                        unchecked-value="N"
                      >
                        Show In Home Page
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>

          <b-tab title="SEO Settings">
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <b-col md="6">
                    <b-row>
                      <b-col lg="12">
                        <b-form-group label-for="homeMetaTitle">
                          <template #label>
                            Meta Title ID<span class="text-danger">*</span>
                            <b-badge variant="info" pill class="help-badge"
                              v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                              <i class="fas fa-question"></i>
                            </b-badge>
                          </template>
                          <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.mj_seo_title_id" />
                          <VValidate 
                            name="Meta Title ID" 
                            v-model="row.mj_seo_title_id" 
                            :rules="{...mrValidation.mj_seo_title_id}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="12">
                        <b-form-group label-for="homeMetaTitle">
                          <template #label>
                            Meta Canonical ID<span class="text-danger">*</span>
                            <b-badge variant="info" pill class="help-badge"
                              v-b-tooltip.hover.right="'Canonical berfungsi untuk menentukan halaman versi utama jika memiliki halaman dengan konten yang duplikat atau serupa.'">
                              <i class="fas fa-question"></i>
                            </b-badge>
                          </template>
                          <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.mj_seo_canonical_id" />
                          <VValidate 
                            name="Meta Canonical ID" 
                            v-model="row.mj_seo_canonical_id" 
                            :rules="{...mrValidation.mj_seo_canonical_id}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="12">
                        <b-form-group label-for="homeMetaDesc">
                          <template #label>
                            Meta Description ID<span class="text-danger">*</span>
                            <b-badge variant="info" pill class="help-badge"
                              v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                              <i class="fas fa-question"></i>
                            </b-badge>
                          </template>
                          <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.mj_seo_desc_id" />
                          <VValidate 
                            name="Meta Desc ID" 
                            v-model="row.mj_seo_desc_id" 
                            :rules="{...mrValidation.mj_seo_desc_id}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="12">
                        <b-form-group label-for="homeMetaTags">
                          <template #label>
                            Meta Keywords ID
                            <b-badge variant="info" pill class="help-badge"
                              v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                              <i class="fas fa-question"></i>
                            </b-badge>
                          </template>
                          <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.mj_seo_keyword_id" />
                          
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row>
                      <b-col lg="12">
                        <b-form-group label-for="homeMetaTitle">
                          <template #label>
                            Meta Title EN<span class="text-danger">*</span>
                            <b-badge variant="info" pill class="help-badge"
                              v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                              <i class="fas fa-question"></i>
                            </b-badge>
                          </template>
                          <b-form-input id="homeMetaTitleEN" placeholder="Meta Title" v-model="row.mj_seo_title_en" />
                          <VValidate 
                            name="Meta Title EN" 
                            v-model="row.mj_seo_title_en" 
                            :rules="{...mrValidation.mj_seo_title_en}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="12">
                        <b-form-group label-for="homeMetaCanonicalEN">
                          <template #label>
                            Meta Canonical EN<span class="text-danger">*</span>
                            <b-badge variant="info" pill class="help-badge"
                              v-b-tooltip.hover.right="'Canonical berfungsi untuk menentukan halaman versi utama jika memiliki halaman dengan konten yang duplikat atau serupa.'">
                              <i class="fas fa-question"></i>
                            </b-badge>
                          </template>
                          <b-form-input id="homeMetaCanonicalEN" placeholder="Meta Title" v-model="row.mj_seo_canonical_en" />
                          <VValidate 
                            name="Meta Canonical EN" 
                            v-model="row.mj_seo_canonical_en" 
                            :rules="{...mrValidation.mj_seo_canonical_en}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="12">
                        <b-form-group label-for="homeMetaDescEN">
                          <template #label>
                            Meta Description EN<span class="text-danger">*</span>
                            <b-badge variant="info" pill class="help-badge"
                              v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                              <i class="fas fa-question"></i>
                            </b-badge>
                          </template>
                          <b-form-textarea rows="5" id="homeMetaDescEN" placeholder="Meta Description" v-model="row.mj_seo_desc_en" />
                          <VValidate 
                            name="Meta Desc EN" 
                            v-model="row.mj_seo_desc_en" 
                            :rules="{...mrValidation.mj_seo_desc_en}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="12">
                        <b-form-group label-for="homeMetaTagsEN">
                          <template #label>
                            Meta Keywords EN
                            <b-badge variant="info" pill class="help-badge"
                              v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                              <i class="fas fa-question"></i>
                            </b-badge>
                          </template>
                          <b-form-tags id="homeMetaTagsEN" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.mj_seo_keyword_en" />
                          
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
        <b-card no-body>
          <template #footer>
            <div class="text-right">
              <b-button class="btn-rounded" variant="transparent" type="button" @click="$router.push({name: 'BoJournal'})">Cancel</b-button>
              <b-button class="btn-rounded" variant="success" type="submit">Submit</b-button>
            </div>
          </template>
        </b-card>
      </b-form>
    </validation-observer>
    
    <b-modal id="modal_new_category" title="Add New Category">
      <validation-observer ref="VFormNewCategory" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitNewCategory)">
          <b-form-group>
            <template #label>Category (ID)</template>
            <b-form-input v-model="dataModalCategory.mjc_category_id" placeholder="e.g. Teknologi"></b-form-input>
            <VValidate name="Category (ID)" v-model="dataModalCategory.mjc_category_id" :rules="mrValidationCategory.mjc_category_id"/>
          </b-form-group>
          <b-form-group>
            <template #label>Category (EN)</template>
            <b-form-input v-model="dataModalCategory.mjc_category_en" placeholder="e.g. Technology"></b-form-input>
            <VValidate name="Category (EN)" v-model="dataModalCategory.mjc_category_en" :rules="mrValidationCategory.mjc_category_en"/>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="closeModal('modal_new_category')">Close</b-button>
        <b-button type="button" variant="primary" @click="submitNewCategory">Submit</b-button>
      </template>
    </b-modal>
      
    <b-modal id="modal_new_tag" title="Add New Category">
      <validation-observer ref="VFormNewTag" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submitNewCategory)">
          <b-form-group>
            <template #label>Tag (ID)</template>
            <b-form-input v-model="dataModalTag.mjt_tag_id" placeholder="e.g. Teknologi"></b-form-input>
            <VValidate name="Tag (ID)" v-model="dataModalTag.mjt_tag_id" rules="required|min:3|max:60"/>
          </b-form-group>
          <b-form-group>
            <template #label>Tag (EN)</template>
            <b-form-input v-model="dataModalTag.mjt_tag_en" placeholder="e.g. Technology"></b-form-input>
            <VValidate name="Tag (EN)" v-model="dataModalTag.mjt_tag_en" rules="required|min:3|max:60"/>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button type="button" variant="secondary" @click="closeModal('modal_new_tag')">Close</b-button>
        <b-button type="button" variant="primary" @click="submitNewTag">Submit</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import QuillEditor from '@/components/QuillEditor'
import Gen from '@/libs/Gen.js'

export default {
  components: {
    QuillEditor,
  },
  props: {
    row: Object,
    mrValidation: Object,
    mrValidationCategory: Object,
    mrCategory: Array,
    mrTags: Array,
    mrUser: Array,
    linkDetailID: String,
    linkDetailEN: String
  },
  data(){
    return {
      tabLanguage: 0,
      tabSeo: 0,
      itemsToolbar: [
        'heading',
        '|',
        'sourceEditing',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'uploadImage',
        'blockQuote',
        '|', 
        'alignment',
        '|', 
        'insertTable','mediaEmbed',
        '|', 
        'outdent', 'indent',
        '|',
        'undo',
        'redo'
      ],
      dataModalCategory: {},
      dataModalTag: {}
    }
  },
  methods: {
    openModalAddNewTags(){
      this.$set(this, 'dataModalTag', {})
      this.$bvModal.show('modal_new_tag')
    },
    openModalAddNewCategory(){
      this.$set(this, 'dataModalCategory', {})
      this.$bvModal.show('modal_new_category')
    },
    submitNewCategory(){
      this.$refs.VFormNewCategory.validate().then(result => {
        if(!result) return

        this.globalComp.swalLoading()

        this.dataModalCategory.mjc_is_active = 'Y'

        Gen.apiRest("/do/BoJournalCategory", {
          data: {
            type: 'add',
            ...this.dataModalCategory
          }
        }).then(res => {
          const newCategory = {
            label: res.data.data.mrCategory.mjc_category_id + ' / ' + res.data.data.mrCategory.mjc_category_en, 
            value: res.data.data.mrCategory.mjc_id
          }
          this.mrCategory.push(newCategory)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.$bvModal.hide('modal_new_category')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || 'Something went wrong!'
          })
        })
      })
    },
    watchTitle(lang){
      if(lang == 'id'){
        if(this.globalComp.isAdd || this.row.mj_have_published != 'Y'){
          this.row.mj_slug_id = this.row.mj_title_id.slugify()
          this.row.mj_seo_canonical_id = this.linkDetailID + this.row.mj_title_id.slugify()
        }
        this.row.mj_seo_title_id = this.row.mj_title_id
        this.row.mj_alt_image = this.row.mj_title_id
      }else if(lang == 'en'){
        if(this.globalComp.isAdd || this.row.mj_have_published != 'Y'){
          this.row.mj_slug_en = this.row.mj_title_en.slugify()
          this.row.mj_slug_jp = this.row.mj_title_en.slugify()
          this.row.mj_seo_canonical_en = this.linkDetailEN + this.row.mj_title_en.slugify()
        }
        this.row.mj_seo_title_en = this.row.mj_title_en
      }
    },
    closeModal(id){
      this.$bvModal.hide(id)
    },
    submitNewTag(){
      this.$refs.VFormNewTag.validate().then(result => {
        if(!result) return

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoJournal", {
          data: {
            type: 'add-tag',
            ...this.dataModalTag
          }
        }).then(res => {
          const newTag = {
            label: res.data.data.mjt_tag_id + ' / ' + res.data.data.mjt_tag_en, 
            value: res.data.data.mjt_id
          }
          this.mrTags.push(newTag)
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.$set(this, 'dataModalTag', {})
            this.closeModal('modal_new_tag')
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    },

    doSubmit(){
      this.$refs.VForm.validate().then(result => {
        if(!result) return

        this.globalComp.swalLoading()

        Gen.apiRest("/do/BoJournal", {
          data: {
            type: this.row.mj_id ? 'update' : 'add',
            ...this.row
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          }).then(() => {
            this.$router.push({name: 'BoJournal'})
          })
        }).catch(err => {
          this.$swal({
            icon: 'error',
            text: err.response.data.message || "Something went wrong!"
          })
        })
      })
    }
  },

  computed: {
    globalComp(){
      return this.$parent.$parent.$parent
    }
  }
}
</script>